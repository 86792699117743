<template>
  <div class="invoice--container__wrap">
    <div class="mb-6 invoice-datetime">
      {{ invoice.createdAt._seconds | formatDate('MMMM do, YYYY [at] hh:mm A', false) }}
    </div>
    <div
      class="invoice--container pa-4 d-flex"
      :class="{
        'flex-column ': $vuetify.breakpoint.smAndDown,
        'align-center': $vuetify.breakpoint.mdAndUp,
      }"
    >
      <div class="d-flex align-center mb-4 mb-md-0">
        <v-icon :color="color" class="mr-2">{{ icon }}</v-icon>

        <div class="invoice-status--text" :style="{ color: `var(--v-${color}-base)` }">
          {{ $t(`patientPortal.invoices.status.${invoiceStatus}`) }}
        </div>
      </div>
      <div class="ml-3 ml-md-8 invoice--details d-flex flex-column">
        <div class="invoice-datetime line-items--container">
          <div v-for="(item, index) in invoice.lineItems" :key="index" class="line-item--name">
            {{ getProductName(item) }}
          </div>
        </div>
        <div class="invoice-total">
          {{ price }}
        </div>
      </div>
      <div
        class="flex-grow-1 d-flex mt-4 mt-md-0"
        :class="$vuetify.breakpoint.mdAndUp ? 'justify-end' : 'justify-center'"
      >
        <invoice-payment-button
          v-if="invoice.status === 'open'"
          :invoiceUrl="invoice.hostedInvoiceUrl"
          :fileName="
            invoice.files.length && (invoice.files[0].displayName || invoice.files[0].fileName)
          "
        />

        <v-btn
          color="primary"
          :text="$vuetify.breakpoint.mdAndUp"
          :block="$vuetify.breakpoint.smAndDown"
          rounded
          v-if="invoice.status === 'paid' && invoice.hostedInvoiceUrl"
          class="text-uppercase"
          @click="viewReceipt"
        >
          <v-icon class="mr-1">mdi-receipt-text-arrow-right</v-icon>Download Receipt</v-btn
        >
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import '../../styles/patient-portal.scss';
.invoice--container__wrap {
  .invoice-datetime {
    font-size: 0.8em;
  }
  .invoice--container {
    background-color: white;
    border-radius: 16px;
    box-shadow: $pretty-shadow;
    .invoice-status--text {
      text-transform: uppercase !important;
      font-weight: bold;
      word-spacing: 9999px;
      width: 105px;
      line-height: 1.2em;
    }
  }
  .invoice-total {
    font-size: 1.4em;
    font-weight: bold;
    line-height: 1em;
  }
  .line-items--container {
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    .line-item--name {
      white-space: nowrap;
      min-width: 0;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
</style>
<script>
// import GET_INVOICE from '../../graphql/Query/GetInvoice.gql';

export default {
  data() {
    return {
      paymentPending: false,
    };
  },
  props: {
    invoice: Object,
  },
  components: {
    InvoicePaymentButton: () => import('./InvoicePaymentButton.vue'),
  },
  methods: {
    // Currently returning null
    // TODO: Address & solve issue with Andy when he gets back

    // async getInvoice() {
    //   const providerId = this.$store.getters.getProviderId();
    //   try {
    //     const res = await this.$apollo.query({
    //       query: GET_INVOICE,
    //       variables: {
    //         providerId,
    //         stripeInvoiceId: this.invoice.stripeInvoiceId,
    //       },
    //       fetchPolicy: 'no-cache',
    //     });
    //     console.log('got updated invoice: ', res);
    //   } catch (e) {
    //     console.log('error getting updated invoice: ', e);
    //   }
    // },
    getProductName(itemId) {
      return (
        this.$store.state.products?.find(({ stripeProductId }) => stripeProductId === itemId)
          ?.name ?? 'Unknown product'
      );
    },
    async viewReceipt() {
      window.open(this.invoice.hostedInvoiceUrl, '_blank');
    },
  },
  computed: {
    color() {
      return {
        paid: 'success',
        pastDue: 'error',
        pending: 'primary',
      }[this.invoiceStatus];
    },
    icon() {
      return {
        paid: 'mdi-check-circle',
        pastDue: 'mdi-alert-circle',
        pending: 'mdi-dots-horizontal-circle',
      }[this.invoiceStatus];
    },
    invoiceStatus() {
      const { status, createdAt } = this.invoice;
      const { _seconds } = createdAt;

      if (status === 'paid') return 'paid';
      const now = this.$store.getters.toTimezone().unix();
      if (this.$store.getters.toTimezone(_seconds).add(5, 'days').unix() < now) return 'pastDue';
      return 'pending';
    },

    price() {
      const { total } = this.invoice;
      const toDollars = Number(total) / 100;
      return `$${toDollars.toFixed(2)}`;
    },
  },
};
</script>
