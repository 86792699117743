<template>
  <pull-to-refresh
    @refresh="getInvoices(currentTab, true)"
    :disabled="loading || $vuetify.breakpoint.mdAndUp"
  >
    <content-title :subtitle="false">
      <template slot="title">
        <div class="d-flex justify-space-between align-center">
          <div>{{ $t('patientPortal.invoices.title') }}</div>
          <v-btn
            v-if="$vuetify.breakpoint.mdAndUp"
            rounded
            color="primary"
            :loading="loading"
            @click="getInvoices(currentTab, true)"
          >
            <v-icon class="mr-1">mdi-refresh</v-icon>
            {{ $t('main.refresh') }}
          </v-btn>
        </div>
      </template>
    </content-title>
    <BillingDisabledBanner v-if="isBillingDisabled" />
    <v-tabs v-model="tab" class="flex-grow-0 mb-6" :show-arrows="false">
      <v-tab v-for="(tab, index) in tabs" :key="index" :ripple="false">
        {{ tab.title }}
      </v-tab>
    </v-tabs>
    <div v-if="loading && !invoices.length">
      <div v-for="(skeleton, i) in Array.from(Array(2).keys())" :key="i">
        <v-skeleton-loader type="text" min-width="100%" class="my-4" />
        <v-skeleton-loader
          type="list-item-two-line, list-item-two-line"
          min-width="100%"
          class="mb-2"
        />
      </div>
    </div>
    <div v-else>
      <invoice-item
        v-for="invoice in invoicesSorted"
        :key="invoice.id"
        :invoice="invoice"
        class="mb-6"
      />
    </div>
    <div class="w-100 d-flex justify-center" v-if="currentTab === 'paid' && invoices.length">
      <div v-if="paidEnd">{{ $t('patientPortal.invoices.end') }}</div>
      <v-btn text v-else @click="getInvoices('paid')" :loading="loading">{{
        $t('main.loadMore')
      }}</v-btn>
    </div>
    <div class="w-100 d-flex justify-center" v-if="!invoices.length && !loading">
      {{ $t('patientPortal.invoices.none') }}
    </div>
  </pull-to-refresh>
</template>
<style lang="scss" scoped>
::v-deep .v-slide-group__prev {
  display: none !important;
}
</style>
<script>
import ContentTitle from '../../components/ContentTitle.vue';
import BillingDisabledBanner from '../../components/PatientPortal/BillingDisabledBanner.vue';
import InvoiceItem from '../../components/PatientPortal/InvoiceItem.vue';
import GET_INVOICES from '../../graphql/Query/GetInvoices.gql';
import GET_PRODUCTS from '../../graphql/Query/GetProducts.gql';

export default {
  data() {
    return {
      paidEnd: false,
      invoices: [],
      loading: false,
      tab: null,
      tabs: [
        {
          title: this.$t('patientPortal.invoices.status.pending'),
          component: 'settings-information',
          value: 'open',
        },
        {
          title: this.$t('patientPortal.invoices.status.paid'),
          component: 'settings-logs',
          value: 'paid',
        },
      ],
    };
  },
  components: {
    BillingDisabledBanner,
    ContentTitle,
    InvoiceItem,
    PullToRefresh: () => import('../../components/PatientPortal/PullToRefresh.vue'),
  },
  computed: {
    currentTab() {
      return this.tabs[this.tab]?.value;
    },
    invoicesSorted() {
      return [...this.invoices].sort((a, b) => {
        if (this.currentTab === 'paid') {
          // eslint-disable-next-line  no-underscore-dangle
          return b.updatedAt._seconds - a.updatedAt._seconds;
        }
        // eslint-disable-next-line  no-underscore-dangle
        return a.updatedAt._seconds - b.updatedAt._seconds;
      });
    },
    isBillingDisabled() {
      return this.$store.getters['patientPortal/isBillingDisabled'];
    },
    stripeCustomerId() {
      const { stripeCustomerId } = this.$store.getters['patientPortal/currentUser'];
      return stripeCustomerId;
    },
  },
  methods: {
    async getInvoices(status, fresh = false) {
      this.loading = true;
      const vars = {};
      if (this.invoices.length && !fresh) {
        vars.startingAfter = this.invoices[this.invoices.length - 1]?.id ?? '';
      }
      if (status === 'paid') {
        vars.limit = 4;
      }
      if (fresh) {
        this.paidEnd = false;
      }
      try {
        const res = await this.$apollo.query({
          query: GET_INVOICES,
          variables: {
            providerId: this.$store.getters['patientPortal/providerId'],
            stripeQuery: {
              customer: this.stripeCustomerId,
              status,

              ...vars,
            },
          },
          fetchPolicy: 'no-cache',
        });
        const { invoices = [] } = res?.data?.getInvoices;
        const newInvoices = invoices ?? [];

        if (!newInvoices?.length) {
          this[`${status}End`] = true;
        }

        if (fresh) {
          this.invoices = newInvoices ?? [];
        } else {
          this.invoices = [...this.invoices, ...(newInvoices ?? [])];
        }
      } catch {
        this.$store.commit('setNotification', {
          color: 'error',
          text: this.$t('patientPortal.invoices.error'),
          showing: true,
          timeout: 10000,
        });
      }
      this.loading = false;
    },
    async getProducts() {
      try {
        const res = await this.$apollo.query({
          query: GET_PRODUCTS,
          variables: {
            providerId: this.$store.getters['patientPortal/providerId'],
          },
          fetchPolicy: 'no-cache',
        });

        const { getProducts: products = [] } = res?.data;
        this.$store.commit('set', { products });
      } catch {
        this.$store.commit('setNotification', {
          color: 'error',
          text: this.$t('patientPortal.invoices.error'),
          showing: true,
          timeout: 10000,
        });
      }
    },
  },
  mounted() {
    this.getProducts();
  },
  watch: {
    tab: {
      immediate: true,
      handler(val) {
        if (val === null || val === undefined) return;
        const { value } = this.tabs[val];
        this.invoices = [];
        this.getInvoices(value, true);
      },
    },
  },
};
</script>
